import React from 'react';
import { Link } from 'react-router-dom';
import { Link as MuiLink } from '@mui/material';
import { styled } from '@mui/styles';

const FingoLink = styled((props) => (
  <MuiLink
    component={Link}
    {...props}
  />
))(() => {});

export default FingoLink;
