import React, { useEffect, useState } from 'react';
import {
  Route,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Typography } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import {
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
} from '@mui/icons-material';
import _ from 'lodash';
import { FullHeightBox } from '../boxes';
import { FadeSwitch } from '../switches';
import useIsMobile from '../../hooks/useIsMobile';
import SidebarItemsList from '../lists/SidebarItemsList';

const drawerWidth = 190;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer)(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const Sidebar = ({ options, showLogOut, goBack }) => {
  const isMobile = useIsMobile('lg');
  const [open, setOpen] = useState(!goBack);
  const { path: routePath } = useRouteMatch();
  const filteredOptions = options.filter(
    ({ show, disabled }) => show && !disabled,
  );
  const history = useHistory();
  const location = useLocation();
  // eslint-disable-next-line operator-linebreak
  const routeLocation =
    location.pathname === routePath
      ? { ...location, pathname: `${routePath}/${filteredOptions[0].path}` }
      : location;

  useEffect(() => {
    if (goBack) {
      setOpen(false);
      return;
    }
    setOpen(!isMobile);
  }, [isMobile, goBack]);

  const toggleDrawer = () => {
    setOpen((prevState) => !prevState);
  };

  const handleBack = () => history.goBack();

  return (
    <FullHeightBox
      sx={(_theme) => ({
        display: 'flex',
        pr: { xs: 0, md: 4 },
        py: { xs: 0.5 },
        [_theme.breakpoints.down('md')]: {
          pl: 0,
          flexDirection: 'column',
        },
      })}
    >
      {goBack && (
      <Button
        startIcon={<ArrowBackOutlinedIcon />}
        onClick={handleBack}
        sx={{
          display: { xs: 'auto', md: 'none' },
          width: 'fit-content',
          py: 1,
        }}
        id="go-back-mobile"
      >
        Volver
      </Button>
      )}
      <Drawer
        variant="permanent"
        open={open}
        sx={(fingoTheme) => ({
          '& .MuiDrawer-paper': {
            position: 'inherit',
            bgcolor: 'transparent',
            border: 0,
          },
          [fingoTheme.breakpoints.down('md')]: {
            display: 'none',
            width: 0,
          },
        })}
        anchor="left"
        onClose={toggleDrawer}
      >
        <DrawerHeader>
          {goBack ? (
            <IconButton onClick={handleBack} color="primary" id="go-back">
              <ArrowBackOutlinedIcon />
            </IconButton>
          ) : (
            <IconButton onClick={toggleDrawer} id="side-collapse">
              {open ? (
                <>
                  <KeyboardDoubleArrowLeft />
                  <Typography>Ocultar</Typography>
                </>
              ) : (
                <KeyboardDoubleArrowRight />
              )}
            </IconButton>
          )}
        </DrawerHeader>
        <SidebarItemsList
          items={options}
          showLogOut={showLogOut}
          open={open}
        />
      </Drawer>
      <FadeSwitch depth={3} customLocation={routeLocation}>
        {filteredOptions.map(({ id, label, path, component }) => {
          if (_.isArray(component)) {
            return component.map(
              ({
                id: subId,
                label: subLabel,
                path: subPath,
                component: subComponent,
              }) => (
                <Route
                  id={subId}
                  key={subLabel}
                  path={`${routePath}/${path}/${subPath}`}
                >
                  {subComponent}
                </Route>
              ),
            );
          }
          return (
            <Route id={id} key={label} path={`${routePath}/${path}`}>
              {component}
            </Route>
          );
        })}
      </FadeSwitch>
    </FullHeightBox>
  );
};

Sidebar.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      path: PropTypes.string,
      icon: PropTypes.element,
      component: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.func,
        PropTypes.array,
      ]),
    }),
  ).isRequired,
  showLogOut: PropTypes.bool,
  goBack: PropTypes.bool,
};

Sidebar.defaultProps = {
  showLogOut: false,
  goBack: false,
};

export default Sidebar;
