import { Box, Tabs, Tab, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import { useInvoiceLucilaCustomColumns, useInvoicePreColumns } from '../../constants';
import { CLIENT_FACTORING_COLUMNS } from '../../constants/risk';
import { formatMoney, getAmortizationStatus } from '../../helpers';
import FingoDataGrid from '../dataGrids/FingoDataGrid';
import BoxCellsGrid from '../grids/BoxCellsGrid';
import FingoMainView from '../layout/FingoMainView';
import { CLIENT_FACTORING_INFORMATION, CLIENT_INVOICES_STATUS_INFORMATION, FACTORING_PROFILE_INVOICES } from '../../graphql';

const ClientFactoringInformation = ({ masterEntity, amortizationData }) => {
  const [selectedTab, setSelectedTab] = useState('exposed');
  const sinceDate = useMemo(() => moment().subtract(6, 'months').format('YYYY-MM-DD'), []);
  const statusShowed = useMemo(() => {
    if (selectedTab === 'exposed') return ['Transfered', 'Debt'];
    return ['Finished'];
  }, [selectedTab]);
  const {
    data: factoringData,
    loading: loadingFactoringData,
  } = useQuery(CLIENT_FACTORING_INFORMATION, {
    variables: {
      companyId: masterEntity?.id,
    },
    skip: !masterEntity,
  });
  const {
    data: invoicesData,
    loading: loadingInvoicesData,
  } = useQuery(CLIENT_INVOICES_STATUS_INFORMATION, {
    variables: {
      companyId: masterEntity?.id,
      dateIssued_Gte: sinceDate,
    },
    skip: !masterEntity,
  });
  const {
    exposedInvoices,
    debtInvoices,
    finishedInvoices,
  } = factoringData || {};
  const {
    amountRejectedInvoices,
    amountCreditNoteInvoices,
    amountOfPendingReintegrations,
  } = invoicesData || {};

  const amortizationStatus = amortizationData ? getAmortizationStatus(amortizationData, 'invoice') : { title: formatMoney(0), subtitle: 'Sin plan de pago' };

  const summaryBoxes = [
    {
      header: 'Información Rechazos',
      title: formatMoney(amountRejectedInvoices?.totalCount || 0),
      subtitle: 'Rechazos (Sii)',
    },
    {
      header: 'Información NC',
      title: formatMoney(amountCreditNoteInvoices?.totalCount || 0),
      subtitle: ' NC (Sii)',
    },
    {
      header: 'Información Reintegros',
      title: formatMoney(amountOfPendingReintegrations?.totalCount || 0),
      subtitle: 'Facturas pendientes para reintegros',
    },
    {
      header: 'Plan de pago',
      title: amortizationStatus.title,
      subtitle: (
        <Typography
          sx={{
            color: amortizationStatus.subtitle === 'Terminado' ? '#FF9801'
              : amortizationStatus.subtitle === 'Activo' ? '#DD362E'
                : amortizationStatus.subtitle === 'Sin plan de pago' ? '#287A23' : 'inherit',
          }}
        >
          {amortizationStatus.subtitle}
        </Typography>
      ),
    },
  ];
  const documentBoxes = [
    {
      header: 'Exposición Actual Vigente',
      title: `$${formatMoney(exposedInvoices?.aggregate || 0)}`,
      subtitle: `${exposedInvoices?.totalCount} facturas`,
    },
    {
      header: 'Exposición Actual en Mora',
      title: `$${formatMoney(debtInvoices?.aggregate || 0)}`,
      subtitle: `${debtInvoices?.totalCount} facturas`,
    },
    {
      header: 'Operaciones Históricas',
      title: `$${formatMoney(finishedInvoices?.aggregate || 0)}`,
      subtitle: `${finishedInvoices?.totalCount} facturas`,
    },
  ];
  const columns = useInvoicePreColumns(useInvoiceLucilaCustomColumns());
  return (
    <>
      <BoxCellsGrid
        options={summaryBoxes}
        loading={loadingInvoicesData}
        rowSpacing={5}
        mt={0.05}
        mb={4}
      />
      <BoxCellsGrid
        options={documentBoxes}
        rowSpacing={5}
        pt={2}
        mb={5}
        loading={loadingFactoringData}
      />
      <Tabs value={selectedTab} onChange={(_, value) => setSelectedTab(value)} centered>
        <Tab label="Exposición Vigente" value="exposed" />
        <Tab label="Operaciones Históricas" value="finished" />
      </Tabs>
      <Box sx={{ height: 350 }}>
        <FingoMainView
          id="client-factoring-datagrid"
          query={FACTORING_PROFILE_INVOICES}
          queryCustomVariables={{
            companyId: masterEntity?.id,
            status: statusShowed,
          }}
          hideReloadButton
          slots={{
            table: FingoDataGrid,
          }}
          slotProps={{
            table: {
              columns,
              includeHeaders: CLIENT_FACTORING_COLUMNS,
              noRowsMessage: () => <Typography>Sin documentos</Typography>,
              rowsPerPageOptions: [25, 50, 100],
            },
          }}
        />
      </Box>
    </>
  );
};

ClientFactoringInformation.propTypes = {
  masterEntity: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  amortizationData: PropTypes.shape({
    amortizationSchedules: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
};

export default ClientFactoringInformation;
