import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  logo: { width: 120 },
}));

const LogoNavItem = ({ logo, isLogged }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const redirectUrl = isLogged ? '/app/home' : t('landing-path');

  const handleClick = () => {
    if (isLogged) {
      history.push(redirectUrl);
    } else {
      window.location.replace(redirectUrl);
    }
  };

  return (
    <Box onClick={handleClick} style={{ cursor: 'pointer' }}>
      <img src={logo} alt="logo" className={classes.logo} />
    </Box>
  );
};

LogoNavItem.propTypes = {
  logo: PropTypes.string.isRequired,
  isLogged: PropTypes.bool.isRequired,
};

export default LogoNavItem;
