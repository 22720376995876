import React from 'react';
import PropTypes from 'prop-types';
import { useApolloClient } from '@apollo/client';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LogoutIcon from '@mui/icons-material/Logout';
import { logOut } from '../../../apollo/reactive-variables/localUpdates';

const LogOutSidebarItem = ({ open }) => {
  const client = useApolloClient();

  return (
    <ListItem disablePadding sx={{ display: 'block', mt: 4 }}>
      <ListItemButton
        sx={{
          minHeight: 48,
          justifyContent: open ? 'initial' : 'center',
          borderTopLeftRadius: 2,
          borderBottomLeftRadius: 2,
        }}
        onClick={() => logOut(client)}
        id="logout"
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 1 : 'auto',
            justifyContent: 'center',
          }}
        >
          <LogoutIcon />
        </ListItemIcon>
        <ListItemText
          primary="Cerrar sesión"
          sx={{ opacity: open ? 1 : 0 }}
          primaryTypographyProps={{ variant: 'h6' }}
        />
      </ListItemButton>
    </ListItem>
  );
};

LogOutSidebarItem.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default LogOutSidebarItem;
