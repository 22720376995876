import { Cached } from '@mui/icons-material';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DEFAULT_ROW_HEIGHT } from '../../constants/data-grid';
import {
  cleanObjectFromUndefined,
  getDefaultFiltersFromHistory,
  historyToFilters,
} from '../../helpers';
import { useFilteredQuery, useLastRefreshDate } from '../../hooks';
import useAvailableHeight from '../../hooks/useAvailableHeight';
import { LoadingIconButton } from '../buttons';
import DataGridCheckbox from '../dataGrids/DataGridCheckbox';
import DataGridLoader from '../dataGrids/DataGridLoader';
import DataGridNoRowsOverlay from '../dataGrids/DataGridNoRowsOverlay';
import FingoStack from '../stacks/FingoStack';
import LastUpdatedDate from '../text/LastUpdatedDate';

const scrollerClass = makeStyles(() => ({
  virtualScroller: { overflowX: 'hidden', overflow: 'overlay' },
}));

const FingoMainView = ({
  id,
  query,
  useUrlFilters,
  queryCustomVariables,
  onCompletedSetRows,
  onCompletedSetLength,
  hideReloadButton,
  slots: { header: HeaderSlot, actions: Actions, table: Table },
  slotProps: { header: headerProps, actions: actionsProps, table: tableProps },
}) => {
  const history = useHistory();
  const classes = scrollerClass();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(
    tableProps.initialPageSize || tableProps.rowsPerPageOptions?.[0] || 25,
  );
  const [globalFilter, setGlobalFilter] = useState('');
  const [orderBy, setOrderBy] = useState(tableProps.initialOrderBy);
  const [lastUpdated, refreshDateHandler] = useLastRefreshDate();
  const [height, refChange] = useAvailableHeight();
  const { defaultFilters } = getDefaultFiltersFromHistory(history);
  const useDefaultFilters = useUrlFilters ? defaultFilters : {};

  useEffect(() => {
    if (useUrlFilters) {
      historyToFilters(history, queryCustomVariables);
    }
  }, []);

  const queryVariables = cleanObjectFromUndefined({
    first: pageSize,
    offset: page * pageSize,
    orderBy,
    globalFilter,
    ...useDefaultFilters,
    ...queryCustomVariables,
  });

  const {
    usableData: rawData,
    loading,
    deletePaginatedAndRefetch,
  } = useFilteredQuery(query, {
    variables: queryVariables,
    notifyOnNetworkStatusChange: true,
  });
  const rowCount = rawData ? onCompletedSetLength(rawData) : 0;
  const data = rawData ? onCompletedSetRows(rawData) : [];
  const refetchCallback = useCallback(() => {
    deletePaginatedAndRefetch();
    refreshDateHandler();
  }, [deletePaginatedAndRefetch, refreshDateHandler]);
  return (
    <>
      {HeaderSlot && (
        <HeaderSlot
          id={id}
          {...headerProps}
          finder={
            headerProps.finder && {
              onFinderChange: (newValue) => {
                setPage(0);
                setGlobalFilter(newValue);
              },
              finderValue: globalFilter,
              ...headerProps.finder,
            }
          }
        />
      )}
      {!hideReloadButton && (
        <FingoStack>
          <LoadingIconButton
            onClick={refetchCallback}
            sx={{ mr: { xs: '0', md: 'auto' } }}
            loading={loading}
            color="gray"
          >
            <Cached sx={{ display: { xs: 'none', md: 'inherit' } }} />
            <LastUpdatedDate lastUpdated={lastUpdated} />
          </LoadingIconButton>
          {Actions && <Actions {...actionsProps} data={data} />}
        </FingoStack>
      )}
      <Box
        id={`${id}-table-box`}
        ref={refChange}
        sx={{
          height,
          width: '100%',
          '& .littleHeader': { fontSize: 11 },
          minHeight: DEFAULT_ROW_HEIGHT,
        }}
      >
        <Table
          rows={loading ? [] : data}
          loading={loading}
          setOrderBy={setOrderBy}
          orderBy={orderBy}
          rowCount={rowCount}
          page={page}
          pageSize={pageSize}
          rowsPerPageOptions={tableProps.rowsPerPageOptions}
          onPageChange={setPage}
          onPageSizeChange={setPageSize}
          keepNonExistentRowsSelected
          scrollbarSize={0}
          rowHeight={DEFAULT_ROW_HEIGHT}
          components={{
            LoadingOverlay: DataGridLoader,
            BaseCheckbox: DataGridCheckbox,
            NoRowsOverlay: DataGridNoRowsOverlay,
          }}
          componentsProps={{ noRowsOverlay: { Message: tableProps.noRowsMessage } }}
          classes={{ virtualScroller: classes.virtualScroller }}
          {...tableProps}
        />
      </Box>
    </>
  );
};

FingoMainView.propTypes = {
  id: PropTypes.string.isRequired,
  query: PropTypes.instanceOf(Object).isRequired,
  onCompletedSetRows: PropTypes.func,
  onCompletedSetLength: PropTypes.func,
  queryCustomVariables: PropTypes.shape(),
  useUrlFilters: PropTypes.bool,
  hideReloadButton: PropTypes.bool,
  slots: PropTypes.shape({
    header: PropTypes.elementType,
    actions: PropTypes.elementType,
    table: PropTypes.elementType.isRequired,
  }).isRequired,
  slotProps: PropTypes.shape({
    header: PropTypes.shape({
      viewTitle: PropTypes.string.isRequired,
      finder: PropTypes.shape({
        searchPlaceHolder: PropTypes.string,
      }),
    }),
    actions: PropTypes.shape(),
    table: PropTypes.shape({
      columns: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
        }),
      ),
      initialOrderBy: PropTypes.string,
      initialPageSize: PropTypes.number,
      noRowsMessage: PropTypes.func.isRequired,
      checkboxSelection: PropTypes.bool,
      onSelectionModelChange: PropTypes.func,
      selectionModel: PropTypes.arrayOf(PropTypes.string),
      isRowSelectable: PropTypes.func,
      keepNonExistentRowsSelected: PropTypes.bool,
      rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
    }).isRequired,
  }).isRequired,
};

FingoMainView.defaultProps = {
  queryCustomVariables: {},
  useUrlFilters: false,
  onCompletedSetRows: (data) => Object.values(data)[0].edges.map((edge) => edge.node),
  onCompletedSetLength: (data) => Object.values(data)[0].totalCount,
  hideReloadButton: false,
};

export default FingoMainView;
