import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { FadeSwitch } from '../switches';

const Router = ({ routes, depth }) => {
  const redirectPath = useMemo(() => routes[0]?.path || '/', [routes]);
  return (
    <FadeSwitch depth={depth}>
      {routes
        .map(({ id, label, path, component }) => (
          <Route id={id} key={label} path={path}>
            {component}
          </Route>
        ))}
      <Route>
        <Redirect to={redirectPath} />
      </Route>
    </FadeSwitch>
  );
};

Router.propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      component: PropTypes.element.isRequired,
      disabled: PropTypes.bool.isRequired,
      show: PropTypes.bool,
      showInNavbar: PropTypes.bool.isRequired,
      subRoutes: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired,
          path: PropTypes.string.isRequired,
          icon: PropTypes.element,
          component: PropTypes.element.isRequired,
          disabled: PropTypes.bool.isRequired,
          show: PropTypes.bool,
        }),
      ),
    }),
  ).isRequired,
  depth: PropTypes.number.isRequired,
};

export default Router;
