import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { Stack, Tooltip, Typography } from '@mui/material';
import { formatMoney } from '../../helpers';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 9,
  borderRadius: 5,
  width: '40%',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: 'primary',
  },
}));

const CreditLineLinearProgress = ({ creditLine }) => {
  const rate = creditLine === null ? 0 : (creditLine.creditUsed / creditLine.limitAmount);
  const value = Math.min(rate, 1);
  const creditUsed = creditLine === null
    ? 'Empresa sin Linea de Crédito'
    : `Monto Utilizado: $${formatMoney(creditLine.creditUsed)}`;
  const totalAmount = creditLine === null
    ? 'Empresa sin Linea de Crédito'
    : `Monto Disponible: $${formatMoney(creditLine.limitAmount)}`;
  return (
    <Stack direction="row" spacing={1}>
      <Typography noWrap sx={{ fontSize: 11 }}>Linea Crédito:</Typography>
      <Tooltip title={creditUsed}>
        <BorderLinearProgress variant="determinate" value={Math.min(value, 1) * 100} />
      </Tooltip>
      <Typography noWrap sx={{ fontSize: 11 }}>{totalAmount}</Typography>
    </Stack>
  );
};

CreditLineLinearProgress.propTypes = {
  creditLine: PropTypes.shape({
    creditUsed: PropTypes.number,
    limitAmount: PropTypes.number,
  }),
};

CreditLineLinearProgress.defaultProps = {
  creditLine: null,
};

export default CreditLineLinearProgress;
