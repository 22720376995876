import React, { useCallback, useMemo, useRef, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Link, ListItemIcon, ListItemText, MenuItem, MenuList, Paper, Stack, useTheme } from '@mui/material';
import OnboardingTooltip from '../../tooltips/OnboardingTooltip';

const LoggedInNavItems = ({ routes }) => {
  const history = useHistory();
  const theme = useTheme();
  const [showSubNav, setShowSubNav] = useState({});
  const hideMenuTimeoutRef = useRef(null);
  const handleMouseLeave = () => {
    hideMenuTimeoutRef.current = setTimeout(() => {
      setShowSubNav({});
    }, 200);
  };
  const handleMouseEnter = useCallback((id) => {
    clearTimeout(hideMenuTimeoutRef.current);
    setShowSubNav({ [id]: true });
  }, []);
  const handleMouseOverMenu = useCallback(() => {
    clearTimeout(hideMenuTimeoutRef.current);
  }, []);
  const handleClick = useCallback((path) => {
    setShowSubNav({});
    history.push(path);
  }, []);

  return useMemo(() => (
    <Stack
      direction="row"
      spacing={3}
      height={55}
      alignItems="center"
      justifyContent="flex-start"
      ml={4}
      sx={{ marginInline: 0 }}
    >
      {routes.map(({ id, label, path, disabled, subRoutes }) => (
        <OnboardingTooltip active={disabled} key={id}>
          <Link
            id={id}
            key={id}
            disabled={disabled}
            to={path}
            onMouseOver={() => handleMouseEnter(id)}
            onMouseLeave={handleMouseLeave}
            activeStyle={{
              color: theme.palette.primary.main,
              fontWeight: '500',
            }}
            variant="h2"
            color="text.main"
            component={NavLink}
            sx={{
              '&:hover': {
                color: 'primary.main',
                textDecoration: 'none',
              },
              textDecoration: 'none',
              whiteSpace: 'nowrap',
            }}
          >
            {label}
          </Link>
          {subRoutes && subRoutes.length > 0
          && showSubNav[id] && !disabled && (
          <Paper
            sx={{
              position: 'absolute',
              mt: '20px',
            }}
            elevation={3}
            onMouseOver={handleMouseOverMenu}
            onMouseLeave={handleMouseLeave}
          >
            <MenuList sx={{ width: 'auto', pb: '8px' }}>
              {subRoutes.filter(
                (r) => r.show && !r.component.length && r.showInSidebar,
              ).map((option) => (
                <MenuItem
                  id={`nav-menu-${option.id}`}
                  key={option.id}
                  onClick={() => handleClick(`${path}/${option.path}`)}
                  sx={{
                    bgcolor: 'white',
                    '&:hover': {
                      bgcolor: 'primary.light',
                      transparency: 0.1,
                    },
                    textDecoration: 'none',
                    whiteSpace: 'nowrap' }}
                >

                  <ListItemIcon>
                    {option.icon}
                  </ListItemIcon>
                  <ListItemText>
                    {option.label}
                  </ListItemText>
                </MenuItem>
              ))}
            </MenuList>
          </Paper>
          )}
        </OnboardingTooltip>
      ))}
    </Stack>
  ), [routes, showSubNav]);
};

LoggedInNavItems.propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      component: PropTypes.element.isRequired,
      show: PropTypes.bool,
      disabled: PropTypes.bool.isRequired,
      showInNavbar: PropTypes.bool.isRequired,
      subRoutes: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired,
          path: PropTypes.string.isRequired,
          icon: PropTypes.element.isRequired,
          show: PropTypes.bool,
          disabled: PropTypes.bool.isRequired,
        }),
      ),
    }),
  ).isRequired,
};

export default LoggedInNavItems;
