import React from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/styles';

const SidebarItem = ({ id, label, path, icon, index, open, disabled, ...props }) => {
  const { path: routePath } = useRouteMatch();
  const theme = useTheme();

  return (
    <ListItem disablePadding sx={{ display: 'block' }}>
      <ListItemButton
        id={id}
        sx={{
          minHeight: 48,
          justifyContent: open ? 'initial' : 'center',
          borderTopLeftRadius: 2,
          borderBottomLeftRadius: 2,
          pl: 1,
        }}
        component={NavLink}
        to={`${routePath}/${path}`}
        isActive={(match, location) => {
          if (location.pathname.includes(path)) return true;
          if (index === 0) {
            return location.pathname === routePath;
          }
          return false;
        }}
        activeStyle={
          { color: theme.palette.primary.main, backgroundColor: 'white' }
        }
        disabled={disabled}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 1 : 'auto',
            justifyContent: 'center',
            ...props,
          }}
        >
          {icon}
        </ListItemIcon>
        <ListItemText
          primary={label}
          sx={{ opacity: open ? 1 : 0 }}
          primaryTypographyProps={{ variant: 'subNavItem', style: { whiteSpace: 'normal' } }}
        />
      </ListItemButton>
    </ListItem>
  );
};

SidebarItem.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  path: PropTypes.string.isRequired,
  icon: PropTypes.element,
  index: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};
SidebarItem.defaultProps = { disabled: true, icon: null };

export default SidebarItem;
