import { List, ListItem, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const BulletList = ({ list }) => (
  <List sx={{ listStyleType: 'disc', py: 0}}>
    {list.map((item, index) => (
      <ListItem key={index} sx={{ display: 'list-item', my: 0, py: 0, mx: 2, px: 0 }}>
        <Typography variant='body1' lineHeight={1.3}>
          {item}
        </Typography>
      </ListItem>
    ))}
  </List>
);

BulletList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.string),
};

BulletList.defaultProps = {
  list: [],
};


export default BulletList;
